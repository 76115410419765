import React from "react";
import { makeStyles } from "@material-ui/core";
// import alpaagoIcon from "../../assets/images/aplaago.png";
import alpaagoIcon from "../../assets/Images/aplaago.png";
import { Link, useNavigate } from "react-router-dom";
import { RootNavTabs } from "../../data/RootNavTabs";
import { routes } from "../../routing/routes";

const customStyles = makeStyles({
  root: {
    maxWidth: "100vw",
    width: "100%",
    position: "sticky",
    top: "0",
    transition: "all .4s ease",
    willChange: "unset",
    backgroundClip: "padding-box",
    position: "relative",
    zIndex: 2,
    fontFamily: "Manrope",
  },
  navWrapper: {
    padding: "0 70px",
    display: "flex",
  },
  iconWrapper: {
    margin: "28px 0px",
    height: "28px",
    position: "relative",
  },
  alpaagoIcon: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    height: "150px",
    right: "0",
    transition: "all .2s ease",
    height: "94px",
  },
  navBar: {
    display: "flex",
    flex: "1",
    justifyContent: "space-between",
    marginLeft: "140px",
  },
  navLinks: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
  },
  tabUL: {
    display: "flex",
    listStyle: "none",
    margin: "0",
    padding: "0",
    boxSizing: "border-box",
    gap: "42px",
  },
  tabLI: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
    margin: "0",
    padding: "0",
  },
  tabA: {
    transition: "all .2s ease",
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    color: "rgb(39, 19, 64)",
    fontWeight: "600",
    fontFamily: "Manrope",
  },
  navBtn: {
    alignItems: "center",
    display: "flex",
  },
  // Targeting very small mobile devices or feature phones (e.g., early Nokia phones, very small Android phones)
  '@media (max-width: 319px)': {
    navWrapper: {
      padding: "0 5px",
      flexDirection: "column",  
    },
    tabUL: {
      display: "flex",
      gap: "8px", 
      flexWrap: "wrap",  
    },
    tabA: {
      fontSize: "10px",  
    },
    navBtn: {
      fontSize: "10px",
      marginLeft:"-50px",
    },
    alpaagoIcon: {
      height: "40px", 
    },
  },

  // Targeting small smartphones or older mobile devices (e.g., iPhone 5/5S, Samsung Galaxy S4, HTC One)
  '@media (min-width: 320px) and (max-width: 480px)': {
    navWrapper: {
      padding: "0 10px",
      flexDirection: "column",
    },
    tabUL: {
      gap: "15px", 
      marginLeft:"-140px",
        zIndex: 0,  

    },
    tabA: {
      fontSize: "12px", 
    },
    navBtn: {
      fontSize: "10px",
      marginTop:"-80px",
      marginLeft:"-85px",
    },
    signupBtn: {
      position: "relative", 
      zIndex: 1,  
    },
    alpaagoIcon: {
      height: "55px", 
      marginTop:"-10px"
    },
  },

  // Targeting mid-sized smartphones and small tablets (e.g., iPhone 6/7/8, iPad Mini, Nexus 7, Samsung Galaxy Tab)
  '@media (min-width: 481px) and (max-width: 768px)': {
    navWrapper: {
      padding: "0 15px",
      flexDirection: "column",
    },
    tabUL: {
      gap: "16px",  
    },
    tabA: {
      fontSize: "6px", 
      marginLeft:"-20px"
    },
    navBtn: {
      fontSize: "4px",

    },
    alpaagoIcon: {
      height: "60px", 
    },
  },

  // Targeting tablets in landscape mode, small laptops, and some large tablets (e.g., iPad Pro, MacBook Air 11", Samsung Galaxy Tab S4)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    navWrapper: {
      padding: "0 20px",
      flexDirection: "row",  
    },
    tabUL: {
      gap: "20px", 
    },
    tabA: {
      fontSize: "16px",  
    },
    navBtn: {
      fontSize: "16px",
    },
    alpaagoIcon: {
      height: "70px", 
    },
  },
});

const RootTopBar = () => {
  const navigate = useNavigate();
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.navWrapper}>
        <div className={classes.iconWrapper}>
          <img
            className={classes.alpaagoIcon}
            src={alpaagoIcon}
            alt="Description of the image"
          />
        </div>
        <div className={classes.navBar}>
          <div className={classes.navLinks}>
            <ul className={classes.tabUL}>
              {RootNavTabs.map((tab) => (
                <li key={tab.id} className={classes.tabLI}>
                  <Link to={tab.tabUrl} className={classes.tabA}>
                    <span>{tab.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.navBtn}>
            <div
              className={"purpleBtn"}
              onClick={() => {
                navigate(routes.SIGN_UP);
              }}
            >
              Sign Up
            </div>
            <div
            style={{marginLeft:"20px"}}
              className={"purpleBtn"}
              onClick={() => {
                navigate(routes.PSIGN_UP);
              }}
            >
              Try PickaaGo
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default RootTopBar;
