import { COLLECTION, SESSION } from "../../data/constant";
import { routes } from "../../routing/routes";

const PsignUpData = {
  userName: {
    key: "userName",
    label: "User Name",
    placeHolder: "Enter Your Name",
    type: "text",
    isRequired: true,
  },
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone number",
    placeHolder: "Enter 10 Digit Phone number",
    type: "number",
    isRequired: true,
  },
  passCode: {
    key: "passCode",
    label: "Passcode",
    placeHolder: "Setup your 4-digit secret Passcode",
    type: "text",
    isRequired: true,
  },
  otp: {
    key: "otp",
    label: "Otp (Enter OTP received through phone call 📞📞)",
    placeHolder: "Enter Otp",
    type: "number",
    isRequired: true,
  },
};

const PloginData = {
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone number",
    placeHolder: "Enter 10 Digit Phone number",
    type: "number",
    isRequired: true,
  },
  passCode: {
    key: "passCode",
    label: "Passcode",
    placeHolder: "Enter your 4-digit secret Passcode",
    type: "text",
    isRequired: true,
  },
};

const PforgotpassCodeData = {
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone number",
    placeHolder: "Enter 10 Digit Phone number",
    type: "number",
    isRequired: true,
  },
  passCode: {
    key: "passCode",
    label: "Passcode",
    placeHolder: "Setup your 4-digit secret Passcode",
    type: "text",
    isRequired: true,
  },
  otp: {
    key: "otp",
    label: "Enter Otp (Enter OTP received through phone call 📞📞)",
    placeHolder: "Ex 1234",
    type: "number",
    isRequired: true,
  },
};

const PauthSession = {
  admin: {
    sessionIdName: SESSION.ADMIN_SESSION_ID,
    redirectPath: routes.ADMIN_LOGIN,
    collectionSession: COLLECTION.ADMIN_SESSION,
  },
  user: {
    sessionIdName: SESSION.USER_SESSION_ID,
    redirectPath: routes.USER_LOGIN,
    collectionSession: COLLECTION.USER_SESSION,
  },
  campus: {
    sessionIdName: SESSION.CAMPUS_SESSION_ID,
    redirectPath: routes.CAMPUS_LOGIN,
    collectionSession: COLLECTION.CAMPUS_SESSION,
  },
  vendor: {
    sessionIdName: SESSION.VENDOR_SESSION_ID,
    redirectPath: routes.VENDOR_LOGIN,
    collectionSession: COLLECTION.VENDOR_SESSION,
  },
  puser: {
    sessionIdName: SESSION.PUSER_SESSION_ID,
    redirectPath: routes.PUSER_LOGIN,
    collectionSession: COLLECTION.PUSER_SESSION,
  },
 
};

export { PsignUpData, PloginData,PforgotpassCodeData, PauthSession };
