import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import SignupBackground from "../../../assets/Images/signupBackground.jpg";
import TextAnimation from "../../../assets/Images/textAnimation.png";
import { SingleInputField } from "../../../components";
import { PsignUpData } from "../../../assets/lib/plogin";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import { CircularLoader } from "../../../components/widgets/CircularLoader";
import { useMediaQuery } from "@mui/material";

const customStyle = makeStyles({
  leftContent: {
    // background: "rgb(74 0 255 / 64%)",
    background: `url(${SignupBackground}) top left repeat`,
    backgroundSize: "cover",
    top: "0",
    bottom: "0",
    minHeight: "100vh", // Change to minHeight: "100vh",
    textAlign: "right",
    opacity: "1",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 50px",
  },
  rightContent: {
    padding: "40px",
  },
  colorBg: {
    position: "absolute",
    content: "''",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgb(74 0 255 / 64%)",
  },
  animatedText: {
    display: "block",
    textShadow: "0 0 80px rgba(255, 255, 255, 0.5)",
    background: `url(${TextAnimation}) repeat-y`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitAnimation: "aitf 80s linear infinite",
    WebkitTransform: "translate3d(0, 0, 0)",
    WebkitBackfaceVisibility: "hidden",
    fontSize: "50px",
    margin: "0px",
  },
  information: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    justifyContent: "center",
    gap: "20px",
    color: "white",
    zIndex: "2",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
 // Media Query for Extra Small Screens (<320px)
 '@media (max-width: 319px)': {
  leftContent: {
    minHeight: "20vh", // Adjust height for small screens
    textAlign: "left",
    opacity: "1",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    padding: "10px 10px",
  },
  rightContent: {
    background: "rgb(74 0 255 / 64%)",
    background: `url(${SignupBackground}) top left repeat`,
    backgroundSize: "cover",
    minHeight: "20vh", // Adjust height for small screens
    textAlign: "left",
    opacity: "1",
    position: "relative",
    padding: "25px 35px",
    color: "white",
  },
  animatedText: {
    fontSize: "30px",
  },
  inputContainer: {
    gap: "5px",
    color: "white",      
  },
},

// Media Query for Small Screens (320px - 480px)
'@media (min-width: 320px) and (max-width: 480px)': {
  leftContent: {
    minHeight: "45vh",
    padding: "15px 30px",
  },
  rightContent: {
    minHeight: "45vh",
    padding: "30px 40px",
  },
  animatedText: {
    fontSize: "35px",
  },
  inputContainer: {
    gap: "8px",
  },
},

// Media Query for Medium Screens (481px - 768px)
'@media (min-width: 481px) and (max-width: 768px)': {
  leftContent: {
    minHeight: "50vh",
    padding: "20px 40px",
  },
  rightContent: {
    minHeight: "45vh",
    padding: "35px 50px",
  },
  animatedText: {
    fontSize: "40px",
  },
  inputContainer: {
    gap: "10px",
  },
},

// Media Query for Large Screens (769px - 1024px)
'@media (min-width: 769px) and (max-width: 1024px)': {
  leftContent: {
    minHeight: "50vh",
    padding: "25px 50px",
  },
  rightContent: {
    minHeight: "45vh",
    padding: "40px 60px",
  },
  animatedText: {
    fontSize: "45px",
  },
  inputContainer: {
    gap: "12px",
  },
},
});

const Layout = (props) => {
  const {
    submitLoading,
    forgotpassCodeState,
    errorFields,
    getForgotAlpinInput,
    handleSubmitForgotAlpin,
    handleOtpSendClick,
    otp,
    isInvalidOtp,
    phoneNoNot10Digit,
    passCodeNot4Digit,
    notExistingUser
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <div className="width-100-per flex" style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
      {submitLoading && <CircularLoader isPoistionAbsolute zIndex={3} />}
      <div className="width-50-per" style={{  width: isMobile? '100%': "50%"}}>
        <div className={classes.leftContent}>
          <div className={classes.colorBg} />
          <div className={classes.information}>
            <h1 className={classes.animatedText}>Pikaago: Redefining Secure Deliveries</h1>
            <span>
            A premium smart parcel box designed to seamlessly integrate security and convenience.
             With a sophisticated PIN access system and advanced imaging technology,
              it ensures every delivery is securely stored and precisely documented,
               offering a trusted solution for modern homes.
            </span>
          </div>
        </div>
      </div>
      <div className="width-50-per" style={{  width: isMobile? '100%': "50%"}}>
        <div className={classes.rightContent}>
          <div className="details">
            <h1 className="yellow-text mr-0">Welcome!</h1>
            <h2>Create New Passcode</h2>

            <div className={classes.inputContainer}>
              <SingleInputField
                content={PsignUpData.phoneNumber}
                value={forgotpassCodeState.phoneNumber}
                getInputData={getForgotAlpinInput}
                errorFields={errorFields}
                isEditable={!otp}
              />
              <SingleInputField
                content={PsignUpData.passCode}
                value={forgotpassCodeState.passCode}
                getInputData={getForgotAlpinInput}
                errorFields={errorFields}
                isEditable={!otp}
              />
              {!!otp && <SingleInputField
                content={PsignUpData.otp}
                value={forgotpassCodeState.otp}
                getInputData={getForgotAlpinInput}
                errorFields={errorFields}
              />}
            </div>

            <div className="mr-tp-20">
              {phoneNoNot10Digit && <p style={{ color: "red", fontSize: "14px" }}>Phone number must be 10 digits long.</p>}
              {notExistingUser && <p style={{ color: "red", fontSize: "14px" }}>The phone number is not registered. Please enter a valid phone number.</p>}
              {passCodeNot4Digit && <p style={{ color: "red", fontSize: "14px" }}>Secret PassCode must be 4 digits long.</p>}
              {isInvalidOtp && <p>
                <span style={{ color: "red" }}>Invalid OTP! </span>Please{' '}
                <span
                  onClick={handleOtpSendClick}
                  className="blue-text cursor-pointer"
                >
                  Resend
                </span>{' '}
                the OTP.
              </p>}
              {!!otp ? <Button
                variant="contained"
                sx={{ bgcolor: "#00C853", width: "120px" }}
                onClick={handleSubmitForgotAlpin}
              >
                Submit
              </Button> : <Button
                variant="contained"
                sx={{ bgcolor: "#00C853", width: "120px" }}
                onClick={handleOtpSendClick}
              >
                Send OTP
              </Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
