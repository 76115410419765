const COLLECTION = {
  ADMIN: "Admins",
  CAMPUS: "Campuses",
  VENDOR: "Vendors",
  MASTER: "Master",
  POD: "Pods",
  POD_TYPE: "PodType",
  TRANSACTIONS: "Transactions",
  USER: "Users",
  INVENTORY: "Inventory",
  DEV_USER: "DevUsers",
  USER_SESSION: "Usersession",
  CAMPUS_SESSION: "CampusSession",
  VENDOR_SESSION: "VendorSession",
  ADMIN_SESSION: "AdminSession",
  // WALLET_DEDUCTIONS:"Walletdeductions"
  WALLET_DEDUCTIONS: "Walletdeductions",
  PRODUCT: "Products",
  VENDORPRODUCTS:"Vendors.vendorProducts",
  PUSERS:"Pusers",
  PPODS:"Ppods",
  PUSER_SESSION: "Pusersession",
  PTRANSACTIONS: "Ptransactions",

  

};

const COLLECTION_COUNTER = {
  CAMPUS: "CampusCounter",
  VENDOR: "VendorCounter",
  MASTER: "Master",
  POD: "PodCounter",
  POD_TYPE: "PodTypeCounter",
  USER: "UserCounter",
  PPODS:"PpodsCounter",
  PUSERS: "PuserCounter",

  

};

const SESSION = {
  USER_SESSION_ID: "userSessionId",
  VENDOR_SESSION_ID: "vendorSessionId",
  CAMPUS_SESSION_ID: "campusSessionId",
  ADMIN_SESSION_ID: "adminSessionId",
  PUSER_SESSION_ID: "puserSessionId",

};

export { COLLECTION, COLLECTION_COUNTER, SESSION };
