import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import {
  createInitialForm,
  fetchLatestId,
  validateRequiredField,
} from "../../../common/common";
import { PforgotpassCodeData } from "../../../assets/lib/plogin";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { addDoc, collection,getDocs,query,where ,updateDoc,doc,getDoc} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import moment from "moment/moment";
import { useEffect } from "react";


function ForgotPasscode() {
  const [forgotpassCodeState, setForgotPasscodeState] = useState(createInitialForm(PforgotpassCodeData));
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [phoneNoNot10Digit, setPhoneNoNot10Digit] = useState(false);
  const [passCodeNot4Digit, setPassCodeNot4Digit] = useState(false);
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [userData, setUserData] = useState([]);
  const [notExistingUser, setNotExistingUser] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const collectionUserRef = collection(db, COLLECTION.PUSERS);
      const baseUserQuery = query(collectionUserRef);

      const dataUserSnapshot = await getDocs(baseUserQuery);

      const collectionUserData = dataUserSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUserData(collectionUserData);
    };
    fetchUserData()
  }, []);


  const navigate = useNavigate();

  const getForgotAlpinInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setForgotPasscodeState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitForgotAlpin = async () => {
    const error = validateRequiredField(PforgotpassCodeData, forgotpassCodeState);
    setErrorFields(error);

    if (forgotpassCodeState.otp !== Number(otp)) {
      setIsInvalidOtp(true)
      return;
    }

    if (error.length === 0) {
      setSubmitLoading(true);
      try {
        
        const UsercollectionRef = collection(db, COLLECTION.PUSERS);
        const q = query(UsercollectionRef, where("userId", "==", forgotpassCodeState.phoneNumber));
        
        try {
        const querySnapshot = await getDocs(q);

          // Check if any document matches
          if (!querySnapshot.empty) {
              querySnapshot.forEach(async (docSnapshot) => {
                  // Get the document reference using the document ID
                  const userDocRef = doc(db, COLLECTION.PUSERS, docSnapshot.id);  // Referencing the specific document by id
                  console.log("userDocRef",userDocRef)
                  
                  // Update the 'alpin' field of the document with the new value
                  await updateDoc(userDocRef, {
                    passCode: forgotpassCodeState.passCode  // Update the 'alpin' field to the new value
                  });

                  console.log(`Document with phone number ${forgotpassCodeState.phoneNumber} updated successfully.`);
              });
          } else {
              console.log("No matching documents found.");
          }
        } catch (error) {
          console.error("Error fetching or updating document: ", error);
        }
    
        setSubmitLoading(false);
        navigate(routes.PUSER_LOGIN);
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
      }
    }
  };


  const sendOTP = async (otp, phoneNumber) => {
    const response = await fetch(`https://2factor.in/API/V1/df62b83f-22ee-11ee-addf-0200cd936042/SMS/${phoneNumber}/${otp}/OTP1`);
    if (!response.ok) {
      throw new Error('Failed to send OTP');
    }
    return response.json();
  };

  const generateOTP = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidAlpin = (passCode) => {
    const alpinRegex = /^\d{4}$/;
    return alpinRegex.test(passCode);
  };

  const isExistingUser =  (phoneNumber) => { 
    const usrArray = userData;  
    // Check if any user's phone number matches the input phone number
    const isMatch = usrArray.some(user => user.userId === phoneNumber);
    // Return true if a match is found, false if no match is found
    return isMatch;
};

  const handleOtpSendClick = async () => {
    let error = validateRequiredField(PforgotpassCodeData, forgotpassCodeState);
    // Filtering out the otp as it will be check while submit button is clicked
    error = error.filter(item => item !== 'otp')
    setErrorFields(error);

    if (isValidPhoneNumber(forgotpassCodeState.phoneNumber)) {
      setPhoneNoNot10Digit(false);
    } else {
      setPhoneNoNot10Digit(true);
    }

    if (isValidAlpin(forgotpassCodeState.passCode)) {
      setPassCodeNot4Digit(false);
    } else {
      setPassCodeNot4Digit(true);
    }
    if (isExistingUser(forgotpassCodeState.phoneNumber)) {
      setNotExistingUser(false);
    } else {
      setNotExistingUser(true);
    }


    if (error.length === 0 && forgotpassCodeState.phoneNumber && isValidPhoneNumber(forgotpassCodeState.phoneNumber) && isValidAlpin(forgotpassCodeState.passCode) && isExistingUser(forgotpassCodeState.phoneNumber)) {
      const generatedOtp = generateOTP();
      setOtp(generatedOtp);
      sendOTP(generatedOtp, forgotpassCodeState.phoneNumber);
    }
  }

  return (
    <Layout
      submitLoading={submitLoading}
      forgotpassCodeState={forgotpassCodeState}
      otp={otp}
      isInvalidOtp={isInvalidOtp}
      phoneNoNot10Digit={phoneNoNot10Digit}
      passCodeNot4Digit={passCodeNot4Digit}
      errorFields={errorFields}
      getForgotAlpinInput={getForgotAlpinInput}
      handleSubmitForgotAlpin={handleSubmitForgotAlpin}
      handleOtpSendClick={handleOtpSendClick}

      notExistingUser={notExistingUser}
    />
  );
}

export default ForgotPasscode;
