import { checkValidMoment } from "../../common/common";

const puserData = {

    userId: {
      key: "userId",
      label: "User ID",
      placeHolder: "Enter User Id",
      type: "number",
      isRequired: true,
    },
    userName: {
      key: "userName",
      label: "User Name",
      placeHolder: "Enter User name",
      type: "text",
      isRequired: true,
    },
    ppin: {
      key: "ppin",
      label: "PPin.",
      placeHolder: "Enter PPin",
      type: "text",
      isRequired: true,
    },
    ppin_created: {
      key: "ppin_created",
      label: "PPin Created",
      format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
      placeHolder: "Enter PPin Created",
      type: "number",
      isRequired: true,
    },
    ppin_expiry: {
      key: "ppin_expiry",
      label: "PPin Expiry",
      format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
      placeHolder: "Enter PPin Expiry",
      type: "number",
      isRequired: true,
    },
    totalTransactions: {
      key: "totalTransactions",
      label: "Total Transactions",
      placeHolder: "Enter Total Transactions",
      type: "text",
    }
  };
  
  const puserTableHeading = [
    {
      key: "userId",
      label: "User ID",
    },
    {
      key: "userName",
      label: "User Name",
    },
    {
      key: "ppin",
      label: "Ppin.",
    },
    {
        key: "ppin_created",
        label: "Ppin Created",
        format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
    },
    {
        key: "ppin_expiry",
        label: "Ppin Expiry",
           format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
      },
    {
      key: "totalTransactions",
      label: "Total Transactions",
    },
  ];
  
  export { puserData, puserTableHeading };
  