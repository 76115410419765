import KitchenIcon from "@mui/icons-material/Kitchen";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { routes } from "../routing/routes";

export const PUserNavTabs = [
 
  {
    id: "puserTransaction",
    label: "Transactions",
    icon: <LocationCityIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.PUSER_TRANSACTION,
  },
  {
    id: "puserProfile",
    label: "Profile",
    icon: <AccountBoxIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.PUSER_PROFILE,
  },
];
