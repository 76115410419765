import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@mui/material";
import { SingleInputField } from "../../../components";
import { walletData } from "../../../assets/lib/wallet";
import { CircularLoader } from "../../../components/widgets/CircularLoader";

const customStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  header: {
    marginTop: theme.spacing(2), // Move the header to the top
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  inputFieldContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "60px",
  },
  inputField: {
    width: "100%",
  },
  button: {
    backgroundColor: theme.palette.success.main,
    width: "120px",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
   // Media Query for Extra Small Screens (<320px)
   '@media (max-width: 319px)': {
    root: {
      padding: theme.spacing(1),  
      width: "70%",
    },
    header: {
      fontSize: "16px",  
      marginTop: theme.spacing(0.5), 
      marginBottom: theme.spacing(0.5),
    },
    inputFieldContainer: {
      marginTop: "40px",  // Adjust for smaller screens
      marginLeft: "10px",
    },
    inputField: {
      width: "80%",  // Ensure input fields don't stretch too much
    },
    button: {
      width: "90px",  // Reduce button width for smaller screens
      padding: theme.spacing(0.5), 
    },
  },

  // Media Query for Small Screens (320px - 480px)
  '@media (min-width: 320px) and (max-width: 480px)': {
    root: {
      padding: theme.spacing(2),  
      width: "80%",
    },
    header: {
      fontSize: "18px",  
      marginTop: theme.spacing(1), 
      marginBottom: theme.spacing(1),
    },
    inputFieldContainer: {
      marginTop: "50px", 
      marginLeft: "12px",
    },
    inputField: {
      width: "85%",  
    },
    button: {
      width: "110px", 
      padding: theme.spacing(1), 
    },
  },

  // Media Query for Medium Screens (481px - 768px)
  '@media (min-width: 481px) and (max-width: 768px)': {
    root: {
      padding: theme.spacing(3),  
      width: "90%",
    },
    header: {
      fontSize: "20px",  
      marginTop: theme.spacing(1.5), 
      marginBottom: theme.spacing(1.5),
    },
    inputFieldContainer: {
      marginTop: "55px",  
      marginLeft: "15px",
    },
    inputField: {
      width: "90%",  
    },
    button: {
      width: "120px",  
      padding: theme.spacing(1.5), 
    },
  },

  // Media Query for Large Screens (769px - 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    root: {
      padding: theme.spacing(4),  
      width: "95%",
    },
    header: {
      fontSize: "24px",  
      marginTop: theme.spacing(2), 
      marginBottom: theme.spacing(2),
    },
    inputFieldContainer: {
      marginTop: "60px",  
      marginLeft: "20px",
    },
    inputField: {
      width: "100%",  
    },
    button: {
      width: "120px",  
      padding: theme.spacing(1.5), 
    },
  },

}));

const Layout = (props) => {
  const { walletAmount, handle, getWalletInput, walletState, rechargeLoader } =
    props;
  const classes = customStyle();

  return (
    <div elevation={3} className={classes.root}>
      {rechargeLoader && <CircularLoader isPoistionAbsolute />}
      <Typography variant="h4" className={classes.header}>
        Your Wallet: ₹{walletAmount}
      </Typography>

      <div className={classes.inputFieldContainer}>
        <SingleInputField
          content={walletData.rechargeAmount}
          value={walletState.rechargeAmount}
          getInputData={getWalletInput}
          errorFields={[]}
          className={classes.inputField}
        />
      </div>

      <Button variant="contained" className={classes.button} onClick={handle}>
        Recharge
      </Button>
    </div>
  );
};

export default Layout;
