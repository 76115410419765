import React from "react";
import { makeStyles } from "@material-ui/core";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const customStyles = makeStyles({
  root: {
    borderRadius: "24px 24px ",
    backgroundColor: "#1B2452",
    fontFamily: "Manrope",  
  },
  footer: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: "100px",
    paddingBottom: "50px",
    gap: "100px",
  },
  contactWrapper: {
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  contactLeft: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    gap: "10px",
  },
  contactRight: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    gap: "14px",
  },
  contactRightItem: {
    gap: "15px",
    color: "white",
    display: "flex",
    flexWrap: "inherit",
    fontWeight: "600",
    alignItems: "center",
  },
  copyRights: {
    textAlign: "right",
    color: "#FFFFFF5C",
    fontSize: "16px",
    fontWeight: "500",
  },
 // Media query for Small Screens (320px - 480px)
 '@media (min-width: 320px) and (max-width: 480px)': {
  footer: {
    paddingTop: "60px", 
    paddingBottom: "40px", 
    gap: "60px", 
  },
  contactWrapper: {
    flexDirection: "column", 
  },
  contactLeft: {
    width: "100%",
    gap: "8px",
  },
  contactRight: {
    width: "100%",
    gap: "12px", 
  },
  contactRightItem: {
    gap: "12px", 
  },
  copyRights: {
    textAlign: "center", 
    fontSize: "15px", 
  },
},

// Media query for Medium Screens (481px - 768px)
'@media (min-width: 481px) and (max-width: 768px)': {
  footer: {
    paddingTop: "80px", 
    paddingBottom: "40px", 
    gap: "80px", 
  },
  contactWrapper: {
    flexDirection: "row", 
    justifyContent: "space-between",
  },
  contactLeft: {
    width: "50%", 
    gap: "10px", 
  },
  contactRight: {
    width: "50%", 
    gap: "14px", 
  },
  copyRights: {
    textAlign: "center",
    fontSize: "16px", 
  },
},

// Media query for Large Screens (769px - 1024px)
'@media (min-width: 769px) and (max-width: 1024px)': {
  footer: {
    paddingTop: "90px", 
    paddingBottom: "50px", 
    gap: "90px",
  },
  contactWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  contactLeft: {
    width: "50%",
    gap: "12px", 
  },
  contactRight: {
    width: "50%", 
    gap: "16px", 
  },
  copyRights: {
    textAlign: "center", 
    fontSize: "17px", 
  },
},
});
const RootFooter = () => {
  const classes = customStyles();
  return (
    <div className={classes.root}>
      <div className={classes.footer}>
        <div className={classes.contactWrapper}>
          <div className={classes.contactLeft}>
            <span className="white-text font-size-36 font-weight-600">
              Contact information
            </span>
            <span className="grey-text">Call us anytime – +91 73974 60454</span>
          </div>
          <div className={classes.contactRight}>
            <div className={classes.contactRightItem}>
              <EmailOutlinedIcon fontSize="small" color="primary" />{" "}
              contact@alpaago.com
            </div>
            <div className={classes.contactRightItem}>
              <PhoneIcon fontSize="small" color="primary" /> +91 73974 60454
            </div>
            <div className={classes.contactRightItem}>
              <LocationOnIcon fontSize="small" color="primary" /> No 1104, 1st
              Main Rd, Stage I, Kengeri Satellite Town, Bengaluru, Karnataka
              560060
            </div>
          </div>
        </div>
        <div className={classes.copyRights}>
          Copyright: © 2024 Alpaago All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default RootFooter;
