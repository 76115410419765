import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { CustomTable, SearchTableInput } from "../../../components";
import PodModal from "./PodModal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import { ppodTableHeading } from "../../../assets/lib/ppod";

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  buttonContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  actionBtn: {
    display: "flex",
    gap: "30px",
  },
  '@media (max-width: 390px)': {
    root: {
      padding: "5px 5px", 
      gap: "60px", 
      width:"80vw",
    },
    buttonContainer: {
      height: "35px",
      justifyContent: "left",
      flexWrap: "wrap",
    },
    actionBtn: {
      marginTop:"5px",
      marginLeft:"-2px",
      gap: "5px",
      flexDirection: "row", 
      height: "35px",
      width:"75vw"
    },
  },
});

const Layout = (props) => {
  const {
    podTableData,
    fetchPodNumber,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    isModalOpen,
    setIsModalOpen,
    handleSubmitModal,
    podState,
    getPodInput,
    errorFields,
    loadingModal,
    pusersData,
    pusersMenu,
    setPusersMenu,
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer} >
        <SearchTableInput
          tableHeading={ppodTableHeading}
          handleSearchQuery={handleSearchQuery}
        />
        <div className={classes.actionBtn}>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
            }}
            onClick={() => {
              setIsModalOpen(true);
              fetchPodNumber();
            }}
          >
            Add PPod
          </Button>
        </div>
      </div>
      <div className="table">
      <CustomTable
        columns={ppodTableHeading}
        data={podTableData}
        // actions={actions}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
      />
      </div>
      <PodModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleSubmitModal={handleSubmitModal}
        podState={podState}
        getPodInput={getPodInput}
        errorFields={errorFields}
        loadingModal={loadingModal}
        pusersData={pusersData}
        pusersMenu={pusersMenu}
        setPusersMenu={setPusersMenu}
      />
    </div>
  );
};

export default Layout;
