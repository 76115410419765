import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom/dist";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import { puserData } from "../../../assets/lib/puser";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { routes } from "../../../routing/routes";

const PuserInfo = () => {
  const { userDocId } = useParams();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const { state: locationState } = useLocation();
  const [userState, setUserState] = useState(createInitialForm(puserData));
  const [errorFields, setErrorFields] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);

  console.log(userDocId); //show data

  const handleSubmitModal = async () => {
    const error = validateRequiredField(puserData, userState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        if (isEdit && userDocId) {
          // save edited data
          const docRef = doc(db, COLLECTION.PUSERS, userDocId);
          await updateDoc(docRef, userState);
        } else {
          // Add new data
          const userCollectionRef = collection(db, COLLECTION.PUSERS);
          await addDoc(userCollectionRef, userState);
          await updateLatestId(COLLECTION_COUNTER.PUSERS, userState.userId);
        }
        setSubmitLoading(false);
        navigate(routes.PUSER_MANAGEMENT);
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
  };
  const getUserInput = (event) => {
    // If the page is open in view state

    if (!isEdit && !isNewAdd) {
      return;
    }
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setUserState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setIsEdit(locationState?.edit || false);
    setIsNewAdd(locationState?.add || false);
    const fetchingInfo = async () => {
      setFetchLoading(true);
      try {
        if (locationState?.add) {
          // If only add data then fetch the id
          const latestId = await fetchLatestId(COLLECTION_COUNTER.PUSERS);
          setUserState((prevState) => ({
            ...prevState,
            [puserData.userId.key]: latestId,
          }));
        } else {
          // For view and edit data
          const docRef = doc(db, COLLECTION.PUSERS, userDocId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const storedData = docSnap.data();
            setUserState((prevState) => {
              const stateData = { ...prevState };
              for (const key in stateData) {
                if (storedData.hasOwnProperty(key)) {
                  stateData[key] = storedData[key];
                }
              }
              return stateData;
            });
          } else {
            throw new Error(
              "Unexpected error occurred the id does not exist in the collection"
            );
          }
        }
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
      setFetchLoading(false);
    };
    fetchingInfo();
  }, []);

  return (
    <>
      <Layout
        isNewAdd={isNewAdd}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        getUserInput={getUserInput}
        userState={userState}
        handleSubmitModal={handleSubmitModal}
        errorFields={errorFields}
        fetchLoading={fetchLoading}
        submitLoading={submitLoading}
        errorOnFetch={errorOnFetch}
      />
    </>
  );
};

export default PuserInfo;
