import React from "react";
import { makeStyles } from "@material-ui/core";
import { 
  TextField, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  TablePagination, 
  CircularProgress, 
  Box ,
} from '@mui/material';


const customStyle =  makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
    formInput: {
      padding: "16px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      fontSize: "18px",
      width: "250px",
      marginLeft:"25px"
    },
});

const Layout = (props) => {
  const {
    filter,
    setFilter,
    loading,
    filteredFiles,
    handleFileClick,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
  } = props;
  const classes = customStyle();

  return (
    <div className={classes.root} style={{marginTop:"-55px"}}>
      <Box sx={{ padding: -2 }}>
      {/* Filter Input */}
      <TextField
        label="Name"
        variant="outlined"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{ marginBottom: 2, width: 200 }}
        size="small" // Small size filter box
      />

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Table */}
          <TableContainer component={Paper} sx={{ marginTop: 0 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'rgb(178, 205, 243)' }}> {/* Table header color */}
                  <TableCell align="left">
                    <Typography variant="body2" fontSize="inherit"> <b>Name</b></Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2" fontSize="inherit"><b>Actions</b></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
  {filteredFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
    <TableRow
      key={file.name}
      sx={{
        backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5", // Alternating row colors
        height: '56px', // Slightly bigger row height for more space
      }}
    >
      <TableCell
        align="left"
        sx={{
          padding: '10px 20px', // More padding for better readability and spacing
        }}
      >
        <Typography variant="body2" fontSize="0.875rem" noWrap>{file.name}</Typography>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          padding: '10px 20px', // More padding for better spacing of the button
        }}
      >
        <Button
          variant="outlined"
          onClick={() => handleFileClick(file.url)}
          size="small"
          sx={{
            backgroundColor: 'rgb(33, 150, 243)', 
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgb(33, 150, 243)', // No hover color change
            },
            padding: '8px 16px', // Slightly larger padding for button
            fontSize: '0.875rem', // Slightly larger font size for the button text
          }}
        >
          Open
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: 2 }}
          />
        </>
      )}
    </Box>
    </div>
  );
};

export default Layout;
