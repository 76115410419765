const ppodData = {
    ppodNumber: {
      key: "ppodNumber",
      label: "PPod Number",
      placeHolder: "Enter PPod Number",
      type: "text",
    },
    location: {
      key: "location",
      label: "Location",
      placeHolder: "Enter Location",
      type: "text",
    },
    userId: {
      key: "userId",
      label: "userId",
      placeHolder: "Enter UserId",
      type: "text",
    },
  };

  
  const ppodTableHeading = [
    {
      key: "ppodNumber",
      label: "PPod Number",
    },
   
    {
      key: "userId",
      label: "UserId",
    },
    {
      key: "location",
      label: "Location",
    },
  ];
  
  export { ppodData, ppodTableHeading, };
  