import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { ppodData } from "../../../assets/lib/ppod";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  setDoc
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useEffect } from "react";

const Ppod = () => {
  const [podState, setPodState] = useState(createInitialForm(ppodData));
  const [pusersData, setPusersData] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isFetchingPodNum, setIsFetchingPodNum] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);
  const [pusersMenu, setPusersMenu] = useState(null);

  useEffect(() => {
    const fetchPusersData = async () => {
      const collectionPusersRef = collection(db, COLLECTION.PUSERS);
      const basePusersQuery = query(collectionPusersRef);

      const dataPusersSnapshot = await getDocs(basePusersQuery);

      const collectionPusersData = dataPusersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setPusersData(collectionPusersData);
    };
    fetchPusersData();
  }, []);

  const getPodInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setPodState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitModal = async () => {
    const error = validateRequiredField(ppodData, podState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        // Add new data
        const ppodCollectionRef = collection(db, COLLECTION.PPODS);
        await addDoc(ppodCollectionRef, {
          ...podState,
          userId: pusersMenu.userId,
        });
       await updateLatestId(COLLECTION_COUNTER.PPODS, podState.ppodNumber);
        setIsModalOpen(false);
        refetchTableData();
        setPodState(createInitialForm(ppodData));
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
    setSubmitLoading(false);
  };

  const fetchPodNumber = async () => {
    setIsFetchingPodNum(true);
    const latestId = await fetchLatestId(COLLECTION_COUNTER.PPODS);
    setPodState((prevState) => ({
      ...prevState,
      [ppodData.ppodNumber.key]: latestId,
    }));
    setIsFetchingPodNum(false);
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.PPODS,
    defaultOrderBy: ppodData.ppodNumber.key,
  });



  return (
    <Layout
      podTableData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      handleSubmitModal={handleSubmitModal}
      podState={podState}
      getPodInput={getPodInput}
      errorFields={errorFields}
      fetchPodNumber={fetchPodNumber}
      loadingModal={isFetchingPodNum || submitLoading}
      pusersData={pusersData}
      pusersMenu={pusersMenu}
      setPusersMenu={setPusersMenu}
    />
  );
};

export default Ppod;
