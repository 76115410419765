import React, { useEffect, useState } from "react";
import { db, storage } from "../../../config/firebase";
import { getDownloadURL, listAll, ref,getMetadata  } from "firebase/storage";


import Layout from "./Layout";

const TransactionsLog = () => {

  const [files, setFiles] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  useEffect(() => {
    const folderRef = ref(storage, 'TLogs');

    listAll(folderRef)
      .then((result) => {
        const filePromises = result.items.map((fileRef) =>
          getDownloadURL(fileRef).then((url) => ({
            name: fileRef.name,
            url,
          }))
        );
        Promise.all(filePromises).then((fileData) => {
          setFiles(fileData);
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
        setLoading(false);
      });
  }, []);

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleFileClick = (url) => {
    window.open(url, '_blank');
  };

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

  return (
    <Layout 
    filter={filter}
    setFilter={setFilter}
    loading={loading}
    filteredFiles={filteredFiles}
    handleFileClick={handleFileClick}
    page={page}
    rowsPerPage={rowsPerPage}
    handleChangePage={handleChangePage}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default TransactionsLog;

  // Assuming collectionRef is already defined and points to a Firestore collection
// const baseQuery = query(
//   collectionRef,
//   where(condition.column, condition.operator, condition.query),
//   where(searchData.column, "==", searchData.query)
// );

// Fetch the query results
//const querySnapshot =  getDocs(baseQuery);

// Map over the snapshot to get an array of document data
//const allData = querySnapshot.docs.map(doc => doc.data());

//console.log(allData);  // This will contain all the documents that match your query