import React from "react";
import { makeStyles } from "@material-ui/core";

const customStyles = makeStyles({
  root: {
    marginTop: "193px",
    backgroundColor: "#1B2452",
    padding: "150px 0px",
    borderRadius: "24px",
    fontFamily: "Manrope",  
  },
  aboutWrapper: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
  },
  leftSide: {
    minWidth: "58%",
    display: "flex",
    flexDirection: "column",
  },
  aboutHeader: {
    fontSize: "18px",
    color: "#EAF083",
    fontWeight: "600",
    display: "flex",
    justifyContent: "space-between",
  },
  storeHeader: {
    marginTop: "20px",
    color: "#FFFFFF",
    fontSize: "48px",
    lineHeight: "1.3em",
  },
  leftDetails: {
    marginTop: "75px",
    color: "rgba(255, 255, 255, 0.75)",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: "rgb(27, 36, 82)",
    fontFamily: "Manrope",  
  },
  righDetails: {
    maxWidth: "40%",
    marginTop: "55px",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: "rgb(27, 36, 82)",
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Manrope",  
  },
   // Media Query for Extra Small Screens (<320px)
   '@media (max-width: 319px)': {
    root: {
      padding: "40px 10px",
      marginTop: "80px",
    },
    aboutWrapper: {
      flexDirection: "column",
    },
    leftSide: {
      minWidth: "100%",
      marginBottom: "20px",
    },
    aboutHeader: {
      fontSize: "14px",
    },
    storeHeader: {
      fontSize: "28px",
      lineHeight: "1.1em",
    },
    leftDetails: {
      marginTop: "20px",
      fontSize: "14px",
    },
    righDetails: {
      maxWidth: "100%",
      fontSize: "14px",
    },
  },

  // Media Query for Small Screens (320px - 480px)
  '@media (min-width: 320px) and (max-width: 480px)': {
    root: {
      padding: "50px 10px",
      marginTop: "100px",
    },
    aboutWrapper: {
      flexDirection: "column",
    },
    leftSide: {
      minWidth: "100%",
      marginBottom: "20px",
    },
    aboutHeader: {
      fontSize: "16px",
    },
    storeHeader: {
      fontSize: "32px",
      lineHeight: "1.2em",
    },
    leftDetails: {
      marginTop: "30px",
      fontSize: "16px",
    },
    righDetails: {
      maxWidth: "100%",
      fontSize: "16px",
    },
  },

  // Media Query for Medium Screens (481px - 768px)
  '@media (min-width: 481px) and (max-width: 768px)': {
    root: {
      padding: "100px 20px",
      marginTop: "120px",
    },
    aboutWrapper: {
      flexDirection: "row",
    },
    leftSide: {
      minWidth: "55%",
      marginBottom: "30px",
    },
    aboutHeader: {
      fontSize: "17px",
    },
    storeHeader: {
      fontSize: "40px",
      lineHeight: "1.25em",
    },
    leftDetails: {
      marginTop: "50px",
      fontSize: "17px",
    },
    righDetails: {
      maxWidth: "45%",
      fontSize: "17px",
    },
  },

  // Media Query for Large Screens (769px - 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    root: {
      padding: "120px 30px",
      marginTop: "140px",
    },
    aboutWrapper: {
      flexDirection: "row",
    },
    leftSide: {
      minWidth: "60%",
    },
    aboutHeader: {
      fontSize: "18px",
    },
    storeHeader: {
      fontSize: "46px",
      lineHeight: "1.3em",
    },
    leftDetails: {
      marginTop: "60px",
      fontSize: "18px",
    },
    righDetails: {
      maxWidth: "40%",
      fontSize: "18px",
    },
  },
});

const About = () => {
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.aboutWrapper}>
        <div className={classes.leftSide}>
          <div className={classes.aboutHeader}></div>
          <div className={classes.storeHeader}>
            A store that is not a store!
          </div>
          <div className={classes.leftDetails}>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;At the store,
            <ul>
              <li>
                Enter your<strong> Al-Pin,</strong>
              </li>
              <li>Pick your snack/book and GO!&nbsp;</li>
            </ul>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;It’s as effortless as it sounds—usually within 10 seconds.&nbsp;&nbsp;
          </div>
        </div>
        <div className={classes.righDetails}>
          <br />•  We understand your desire for variety. Enjoy fresh snack options {" "}
          <span className="text-decoration-underline">
            <strong> nearly  every day</strong>.
          </span>
          <br />• We recognize your time is valuable. Experience seamless checkout with{" "}
          <span className="text-decoration-underline">
            <strong>our Al-Pin login.</strong>
          </span>
          <br />• We know you value budgeting.Manage your spending effortlessly with the
          <u>
            <b>Alpaago Wallet</b>
          </u>
          , ensuring you never overspend.
        </div>
      </div>
    </div>
  );
};

export default About;
