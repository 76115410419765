import React from "react";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { SingleInputField, TwoInputField } from "../../../components";
import { contactUsData } from "../../../assets/lib/contactUs";

const customStyles = makeStyles({
  root: {
    padding: "100px 0px",
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    fontFamily: "Manrope",  
  },
  header: {
    margin: "26px 0px",
    color: "#271340",
    maxWidth: "50%",
    fontSize: "64px",
    fontWeight: 600,
    lineHeight: 1.1,
    fontFamily: "Manrope",  
  },
  contactWrapper: {
    marginTop: "25px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  contactLeft: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  contactRight: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  contactDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    marginTop: "48px",
    fontFamily: "Manrope",  
  },
  contactDetailItem: {
    gap: "20px",
    color: "#271340",
    display: "flex",
    flexWrap: "inherit",
    fontWeight: "600",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "Manrope",  
  },
  contactIcon: {
    padding: "8px",
    backgroundColor: "#271340",
    borderRadius: "50%",
    height: "22px",
    width: "22px",
  },
  infoWrapper: {
    padding: "40px",
    backgroundColor: "white",
    borderStyle: "solid",
    borderWidth: "1px 1px 1px 1px",
    borderColor: "#7878786B",
    borderRadius: "16px",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.08)",
    gap: "15px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Manrope",  
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  contactBtn: {
    width: "100%",
  },
  // Media Query for iPhone 13 Pro / Extra Small Screens (max-width: 390px)
  '@media (max-width: 390px)': {
    header: {
      fontSize: "32px", // Reduced font size for smaller screens
    },
    contactWrapper: {
      flexDirection: "column", // Stack contact sections vertically
    },
    contactLeft: {
      width: "100%", // Full width for contact left section
    },
    contactRight: {
      width: "100%", // Full width for contact right section
      marginLeft: "-10px", // Slight adjustment for spacing
    },
    infoWrapper: {
      padding: "20px", // Reduced padding for smaller screens
    },
    formLabel: {
      fontSize: "14px", // Smaller font size for form labels
    },
  },

  // Media Query for Small Screens (320px - 480px)
  '@media (min-width: 320px) and (max-width: 480px)': {
    header: {
      fontSize: "36px", // Slightly larger font size for small screens
    },
    contactWrapper: {
      flexDirection: "column",
    },
    contactLeft: {
      width: "100%",
    },
    contactRight: {
      width: "100%",
      marginLeft: "-10px",
    },
    infoWrapper: {
      padding: "25px", // Slightly more padding than the smallest screens
    },
    formLabel: {
      fontSize: "15px", // Small adjustment for medium-small screens
    },
  },

  // Media Query for Medium Screens (481px - 768px)
  '@media (min-width: 481px) and (max-width: 768px)': {
    header: {
      fontSize: "48px", // Adjust header font size for tablet screens
    },
    contactWrapper: {
      flexDirection: "row", // Side-by-side layout for tablet screens
      justifyContent: "space-between",
    },
    contactLeft: {
      width: "48%", // Adjust width of contact sections for tablet view
    },
    contactRight: {
      width: "48%", // Adjust width of contact sections for tablet view
    },
    infoWrapper: {
      padding: "30px", // Add some padding for better spacing
    },
    formLabel: {
      fontSize: "16px", // Default font size for form labels
    },
  },

  // Media Query for Large Screens (769px - 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    header: {
      fontSize: "56px", // Adjust header size for larger tablets or small desktops
    },
    contactWrapper: {
      flexDirection: "row", // Keep side-by-side layout for larger screens
      justifyContent: "space-between",
    },
    contactLeft: {
      width: "48%", // Adjust widths for larger tablets or small desktops
    },
    contactRight: {
      width: "48%",
    },
    infoWrapper: {
      padding: "35px", // Add more padding for larger screens
    },
    formLabel: {
      fontSize: "17px", // Increase font size for larger form labels
    },
  },

  // Media Query for Extra Large Screens (>=1025px)
  '@media (min-width: 1025px)': {
    header: {
      fontSize: "64px", // Use original header font size for large screens
    },
    contactWrapper: {
      flexDirection: "row", // Maintain side-by-side layout for large screens
      justifyContent: "space-between",
    },
    contactLeft: {
      width: "55%", // Default width for contact left section
    },
    contactRight: {
      width: "40%", // Default width for contact right section
    },
    infoWrapper: {
      padding: "40px", // Use default padding for larger screens
    },
    formLabel: {
      fontSize: "18px", // Larger font size for form labels
    },
  },
});

const Layout = (props) => {
  const { contactUsState, getContactUsInput } = props;
  const classes = customStyles();
  return (
    <div className={classes.root}>
      <span className="font-size-18  purple-text">
        <span className="grey-text">Home &gt; </span>
        <span>Contact Us</span>
      </span>
      <div className={classes.contactWrapper}>
        <div className={classes.contactLeft}>
          <div className="flex-direction-column gap-12">
            <span className="light-purple-text font-weight-600 font-size-18">
              Contact
            </span>
            <span className="purple-text font-weight-600 font-size-36">
              Don’t hesitate to reach out to us
            </span>
            <span className="grey-text font-weight-600 font-size-18">
              Contact us anytime
            </span>
          </div>
          <div className={classes.contactDetails}>
            <div className={classes.contactDetailItem}>
              <span className={classes.contactIcon}>
                <EmailOutlinedIcon
                  fontSize="small"
                  sx={{ color: "rgb(234, 240, 131)" }}
                />
              </span>
              contact@alpaago.com
            </div>
            <div className={classes.contactDetailItem}>
              <span className={classes.contactIcon}>
                <PhoneIcon
                  fontSize="small"
                  sx={{ color: "rgb(234, 240, 131)" }}
                />
              </span>
              +91 73974 60454
            </div>
            <div className={classes.contactDetailItem}>
              <span className={classes.contactIcon}>
                <LocationOnIcon
                  fontSize="small"
                  sx={{ color: "rgb(234, 240, 131)" }}
                />
              </span>
              No 1104, 1st Main Rd, Stage I, Kengeri Satellite Town, Bengaluru,
              Karnataka 560060
            </div>
          </div>
        </div>
        <div className={classes.contactRight}>
          <div className={classes.infoWrapper}>
            <TwoInputField
              content={contactUsData.contactName}
              value={contactUsState.contactName}
              secondContent={contactUsData.contactEmail}
              secondValue={contactUsState.contactEmail}
              getInputData={getContactUsInput}
            />
            <SingleInputField
              content={contactUsData.contactCompany}
              value={contactUsState.contactCompany}
              getInputData={getContactUsInput}
            />
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>
                {contactUsData.message.label} :
              </span>
              <TextareaAutosize
                aria-label="Your Message"
                placeholder={contactUsData.message.placeHolder}
                minRows={5}
                name={contactUsData.message.key}
                value={contactUsState.message}
                onChange={getContactUsInput}
                style={{
                  fontFamily: "Manrope",  
                  fontSize: "14px",
                }}
              />
            </div>
            <div className={"purpleBtn"}>
              <span className={classes.contactBtn}>Contact Us</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
