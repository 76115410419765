import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  CircularProgress,
  Box,
  Modal,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
});

const Layout = (props) => {
  const {
    loading,
    documents,
    handleViewImage,
    modalOpen,
    setModalOpen,
    imageURL,
    setImageURL,  // Receive setImageURL as a prop
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  const [imageNotFound, setImageNotFound] = useState(false);
  const classes = customStyle();

  const handleViewImageClick = async (docId) => {
    try {
      const imagePath = `PpodImages/${docId}`;  // Make sure docId matches the image filename
      const imageURL = await handleViewImage(docId); // This should return the URL from Firebase Storage
      if (imageURL) {
        setImageURL(imageURL);  // Use setImageURL here as it's passed from the parent
        setImageNotFound(false); // Set to false since image exists
      } else {
        setImageNotFound(true);
      }
      setModalOpen(true); // Open the modal after checking image
    } catch (error) {
      setImageNotFound(true);
      console.error("Error fetching image:", error);
    }
  };

  return (
    <div className={classes.root}>
      <Box sx={{ padding: -2 }}>
        {/* Loading Spinner */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Table */}
            <TableContainer component={Paper} sx={{ marginTop: 0 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "rgb(178, 205, 243)" }}>
                    <TableCell align="left">
                      <Typography variant="body2" fontSize="inherit">
                        <b>Transaction ID</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" fontSize="inherit">
                        <b>Message</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" fontSize="inherit">
                        <b>Time</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" fontSize="inherit">
                        <b>Picture</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((doc, index) => (
                      <TableRow
                        key={doc.id}
                        sx={{
                          backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5",
                          height: "56px",
                        }}
                      >
                        <TableCell align="left" sx={{ padding: "10px 20px" }}>
                          {doc.id}
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "10px 20px" }}>
                          {doc.message || "No details available"}
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "10px 20px" }}>
                          {doc.timestamp
                            ? new Date(doc.timestamp.seconds * 1000).toLocaleString()
                            : "No details available"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "10px 20px" }}>
                          <Button
                            variant="outlined"
                            onClick={() => handleViewImageClick(doc.id)}
                            size="small"
                            sx={{
                              backgroundColor: "rgb(33, 150, 243)",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "rgb(33, 150, 243)",
                              },
                              padding: "8px 16px",
                              fontSize: "0.875rem",
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={documents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ marginTop: 2 }}
            />

            {/* Modal */}
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              aria-labelledby="image-modal-title"
              aria-describedby="image-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  textAlign: "center",
                }}
              >
                {imageNotFound ? (
                  <Typography>No image found</Typography>
                ) : (
                  imageURL && <img src={imageURL} alt="Document Image" style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                )}
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </div>
  );
};

export default Layout;
