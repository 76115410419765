import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { RootTopBar } from "../../components";
import RootFooter from "../../components/RootFooter";
import { routes } from "../../routing/routes";

const RootLayout = () => {
  const { pathname } = useLocation();
  const [showRootBar, setShowRootBar] = useState(false);

  useEffect(() => {
    const isActiveTab = !(
      pathname.includes(routes.ADMIN) ||
      pathname.includes(routes.USERS) ||
      pathname.includes(routes.VENDOR) ||
      pathname.includes(routes.CAMPUS) ||
      pathname.includes(routes.PUSERS) 

    );
    setShowRootBar(isActiveTab);
  }, [pathname]);

  return (
    <main className="root-layout">
      <div className="flex-direction-column width-100-per height-100-per">
        {showRootBar && <RootTopBar />}
        <Outlet />
        {showRootBar && <RootFooter />}
      </div>
    </main>
  );
};

export default RootLayout;
