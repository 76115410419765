import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";

const customStyles = makeStyles({
  headerWrapper: {
    width: "90%",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
    position: "relative",
    flexWrap: "wrap",
    alignContent: "flex-start",
    flexDirection: "column",
    fontFamily: "Manrope",  
  },
  headerContainer: {
    textAlign: "left",
    maxWidth: "700px",
  },
  header: {
    margin: "0px",
    fontWeight: "600",
    letterSpacing: "-0.027em",
    lineHeight: "1.1",
    textTransform: "none",
    color: "#271340",
    fontSize: "64px",
    fontWeight: "600", 
  },
  headerBodyContainer: {
    marginTop: "20px",
    maxWidth: "520px",
    color: "#271340",
    fontSize: "20px",
    fontWeight: "300",
  },
  headerBtnWrapper: {
    display: "flex",
    marginTop: "76px",
    gap: "16px",
  },
  divider: {
    marginTop: "32px",
    color: "#80808047",
    borderTop: "1px solid #80808047",
    width: "346px",
  },
  headerUsers: {
    marginTop: "32px",
    fontWeight: "400",
    display: "flex",
    width: "330px",
    fontSize: "18px",
    alignItems: "center",
    gap: "10px",
  },
 // Media Query for Extra Small Screens (<320px) 
 '@media (max-width: 319px)': {
  header: {
    fontSize: "36px",  
  },
  headerBodyContainer: {
    fontSize: "16px",  
    maxWidth: "100%",
  },
  headerBtnWrapper: {
    flexDirection: "column",  
    marginTop: "60px",  
  },
  divider: {
    width: "100%", 
  },
  headerUsers: {
    fontSize: "16px", 
    width: "100%",
    justifyContent: "center",
  },
},

// Media Query for Small Screens (320px - 480px)
'@media (min-width: 320px) and (max-width: 480px)': {
  header: {
    fontSize: "42px",  
  },
  headerBodyContainer: {
    fontSize: "18px", 
    maxWidth: "100%", 
  },
  headerBtnWrapper: {
    flexDirection: "column",  // Stack buttons vertically
    marginTop: "80px",  // More space between buttons
  },
  divider: {
    width: "100%",  // Divider width to 100% of screen width
  },
  headerUsers: {
    width: "100%",  // Full width for users section
    fontSize: "18px",  // Slightly larger font for users info
    justifyContent: "center", 
    marginBottom: "-40px",  // Adjust margin for spacing
  },
},

// Media Query for Medium Screens (481px - 768px)
'@media (min-width: 481px) and (max-width: 768px)': {
  header: {
    fontSize: "48px",  // Larger font for medium screens
  },
  headerBodyContainer: {
    fontSize: "20px",  // Standard font size for medium screens
    maxWidth: "100%",  // Container full width
  },
  headerBtnWrapper: {
    flexDirection: "row",  // Button layout to row
    marginTop: "90px",  // Adjust top margin for better spacing
  },
  divider: {
    width: "80%",  // Divider width for medium screens
  },
  headerUsers: {
    width: "80%",  // Adjust width for users section
    fontSize: "18px",  // Keep font size consistent
    justifyContent: "center", 
  },
},

// Media Query for Large Screens (769px - 1024px)
'@media (min-width: 769px) and (max-width: 1024px)': {
  header: {
    fontSize: "56px",  // Larger font for larger screens
  },
  headerBodyContainer: {
    fontSize: "22px",  // Increase body font size slightly
    maxWidth: "600px",  // Set a max width for body container
  },
  headerBtnWrapper: {
    flexDirection: "row",  // Maintain row layout for buttons
    marginTop: "100px",  // Adjust space between buttons
  },
  divider: {
    width: "70%",  // Divider width for larger screens
  },
  headerUsers: {
    width: "70%",  // Adjust user section width
    fontSize: "20px",  // Slightly larger font for users info
    justifyContent: "flex-start",  // Align users section to left
  },
},
});

const Header = () => {
  const classes = customStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerContainer}>
        <h1 className={classes.header}>
          First-of-its-kind 24*7 retail platform for your community
        </h1>
      </div>
      <div className={classes.headerBodyContainer}>
        Snacks, stationary and books in 3 simple steps, right next to your desk.
      </div>
      <div className={classes.headerBtnWrapper}>
        <div
          className={"purpleBtn"}
          onClick={() => {
            navigate(routes.USER_LOGIN);
          }}
        >
          Login <ArrowForwardIcon />
        </div>
        <div
          className={"purpleBtn"}
          onClick={() => {
            navigate(routes.SIGN_UP);
          }}
        >
          Create your secret alpin <ArrowForwardIcon />
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.headerUsers}>
        <h1 className={classes.header}>1K+</h1>
        <span>Active users around the world</span>
      </div>
    </div>
  );
};

export default Header;
