import { COLLECTION, SESSION } from "../../data/constant";
import { routes } from "../../routing/routes";

const signUpData = {
  userName: {
    key: "userName",
    label: "User Name",
    placeHolder: "Eg. Akilesh",
    type: "text",
    isRequired: true,
  },
  phoneNumber: {
    key: "phoneNumber",
    label: "Enter 10 Digit Phone number",
    placeHolder: "9123456788",
    type: "number",
    isRequired: true,
  },
  newAlpin: {
    key: "newAlpin",
    label: "Setup your 4-digit secret Alpin",
    placeHolder: "Ex 7411",
    type: "text",
    isRequired: true,
  },
  otp: {
    key: "otp",
    label: "Enter Otp (Enter OTP received through phone call 📞📞)",
    placeHolder: "Ex 1234",
    type: "number",
    isRequired: true,
  },
};

const loginData = {
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone number",
    placeHolder: "9123456788",
    type: "number",
    isRequired: true,
  },
  alpin: {
    key: "alpin",
    label: "Enter Alpin",
    placeHolder: "Ex 7411",
    type: "text",
    isRequired: true,
  },
};

const forgotAlpinData = {
  phoneNumber: {
    key: "phoneNumber",
    label: "Enter your 10 Digit Phone number",
    placeHolder: "9123456788",
    type: "number",
    isRequired: true,
  },
  newAlpin: {
    key: "newAlpin",
    label: "Setup your new 4-digit secret Alpin",
    placeHolder: "Ex 7411",
    type: "text",
    isRequired: true,
  },
  otp: {
    key: "otp",
    label: "Enter Otp (Enter OTP received through phone call 📞📞)",
    placeHolder: "Ex 1234",
    type: "number",
    isRequired: true,
  },
};

const authSession = {
  admin: {
    sessionIdName: SESSION.ADMIN_SESSION_ID,
    redirectPath: routes.ADMIN_LOGIN,
    collectionSession: COLLECTION.ADMIN_SESSION,
  },
  user: {
    sessionIdName: SESSION.USER_SESSION_ID,
    redirectPath: routes.USER_LOGIN,
    collectionSession: COLLECTION.USER_SESSION,
  },
  campus: {
    sessionIdName: SESSION.CAMPUS_SESSION_ID,
    redirectPath: routes.CAMPUS_LOGIN,
    collectionSession: COLLECTION.CAMPUS_SESSION,
  },
  vendor: {
    sessionIdName: SESSION.VENDOR_SESSION_ID,
    redirectPath: routes.VENDOR_LOGIN,
    collectionSession: COLLECTION.VENDOR_SESSION,
  },
 
};

export { signUpData, loginData,forgotAlpinData, authSession };
