import React from "react";
import { CustomTable } from "../../../components";
import { userTransactionTableHeading } from "../../../assets/lib/userTransaction";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  // Media Query for Extra Small Screens (<320px)
  '@media (max-width: 319px)': {
    root: {
      padding: "60px 5px",
      width: "90%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },

  // Media Query for Small Screens (320px - 480px)
  '@media (min-width: 320px) and (max-width: 480px)': {
    root: {
      padding: "60px 5px",
      width: "90%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },

  // Media Query for Medium Screens (481px - 768px)
  '@media (min-width: 481px) and (max-width: 768px)': {
    root: {
      padding: "60px 20px",
      width: "95%",
      display: "flex",
      flexDirection: "column",
      gap: "25px",
    },
  },

  // Media Query for Large Screens (769px - 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    root: {
      padding: "70px 30px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
  },

});

const Layout = (props) => {
  const {
    userTransactionData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = props;
  const classes = customStyle();

  return (
    <div className={classes.root}>
      <CustomTable
        columns={userTransactionTableHeading}
        data={userTransactionData}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
        // renderCell={(row, column) => {
        //   // Custom rendering logic for payment history column
        //   if (column.id === "paymentHistory") {
        //     // Assuming payment history is stored as an array in the transaction data
        //     return row.paymentHistory.join(", ");
        //   }
        //   // Render other columns normally
        //   return row[column.id];
        // }}
      />
    </div>
  );
};

export default Layout;
