import React from "react";
import { makeStyles } from "@material-ui/core";
import { podHomeDetails } from "../../../assets/lib/home";
import GoPods from "../../../assets/Images/goPods.jpeg";
import GoPodsUltra from "../../../assets/Images/goPodsUltra.jpeg";
import GoPodsBook from "../../../assets/Images/goPodsBooks.jpg";

const customStyles = makeStyles({
  root: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Manrope",  
  },
  heading: {
    padding: "15px 15px 0px",
    width: "100%",
    color: "#271340",
    fontSize: "36px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "31px",
    color: "rgba(39, 19, 64, 0.8)",
  },
  podDetails: {
    marginTop: "50px",
    padding: "10px",
    display: "flex",
  },
  podWrapper: {
    padding: "10px",
    width: "33.33%",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  podLabel: {
    color: "#271340",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "1.2",
  },
  snackDetailsWrapper: {
    paddingTop: "120px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "700px",
  },
  sideHeading: {
    padding: "0px 10px",
    color: "#271340",
    fontSize: "36px",
    fontWeight: 600,
  },
  snackDetail: {
    padding: "30px 10px",
    gap: "18px",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: 1.7,
  },
  snackP: {
    color: "rgba(39, 19, 64, 0.8)",
  },
  // Media Query for Small Screens (320px - 480px)
  '@media (min-width: 320px) and (max-width: 480px)': {
    heading: {
      fontSize: "24px", // Font size adjustment for small phones
      padding: "10px 0",
    },
    content: {
      fontSize: "16px", // Adjust content font size
      lineHeight: "24px",
    },
    podDetails: {
      flexDirection: "column",
      marginTop: "30px",
      marginLeft: "-20px",
    },
    podWrapper: {
      width: "100%", // Full width for smaller devices
      padding: "5px",
    },
    podLabel: {
      fontSize: "18px", // Adjust label font size for smaller screens
    },
    snackDetailsWrapper: {
      paddingTop: "60px",
      maxWidth: "100%", // Use full width
    },
    sideHeading: {
      fontSize: "28px", // Adjust heading font size
    },
    snackDetail: {
      padding: "10px 5px",
      fontSize: "16px", // Slightly larger font for snack details
    },
  },

  // Media Query for Medium Screens (481px - 768px)
  '@media (min-width: 481px) and (max-width: 768px)': {
    heading: {
      fontSize: "30px", // Adjust heading font size for medium screens
    },
    content: {
      fontSize: "17px", // Slightly smaller than default
    },
    podWrapper: {
      width: "50%", // Two-column layout for medium screens
    },
    podLabel: {
      fontSize: "18px", // Maintain reasonable size for pod labels
    },
    snackDetailsWrapper: {
      paddingTop: "100px",
      maxWidth: "80%",
    },
    sideHeading: {
      fontSize: "32px", // Bigger heading for tablets
    },
    snackDetail: {
      padding: "20px 10px",
      fontSize: "17px",
    },
  },

  // Media Query for Large Screens (769px - 1024px)
  '@media (min-width: 769px) and (max-width: 1024px)': {
    heading: {
      fontSize: "34px", // Larger font size for tablets and small desktops
    },
    content: {
      fontSize: "18px", // Default font size for larger devices
    },
    podWrapper: {
      width: "33.33%", // Three-column layout for larger screens
    },
    podLabel: {
      fontSize: "20px", // Larger labels for bigger screens
    },
    snackDetailsWrapper: {
      paddingTop: "100px",
      maxWidth: "700px",
    },
    sideHeading: {
      fontSize: "34px",
    },
    snackDetail: {
      padding: "30px 15px",
      fontSize: "18px",
    },
  },

  // Media Query for Extra Large Screens (>1024px)
  '@media (min-width: 1025px)': {
    heading: {
      fontSize: "36px", // Keep large font size for larger devices
    },
    content: {
      fontSize: "18px", // Maintain default font size for larger screens
    },
    podWrapper: {
      width: "33.33%",
      padding: "15px", // More padding for extra large screens
    },
    podLabel: {
      fontSize: "20px", // Consistent font size for labels
    },
    snackDetailsWrapper: {
      paddingTop: "120px",
      maxWidth: "700px",
    },
    sideHeading: {
      fontSize: "36px", // Keep consistent heading size
    },
    snackDetail: {
      padding: "30px 15px",
      fontSize: "18px",
    },
  },
});

const Product = () => {
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.heading}>Products</div>
      <div className={classes.content}>
        <p>
        An intuitive interface that anyone from kindergartners to grandparents can use effortlessly,
         even without a phone. Elevating your purchasing experience with ease and innovation.
         Being SIMPLE simply means removing all unnecessary steps. With everything running behind the scenes,
         you can just focus on being awesome!
        </p>
      </div>
      <div className={classes.podDetails}>
        {podHomeDetails.map((pod) => (
          <div className={classes.podWrapper} key={pod.id}>
            <div className={classes.podLabel}>{pod.label}</div>
            <img
              src={
                pod.id === "goPodBasic"
                  ? GoPods
                  : pod.id === "goPodUltra"
                  ? GoPodsUltra
                  : GoPodsBook
              }
              alt={pod.label}
              width={358}
            />
            <ul>
              {!!pod.item &&
                pod.item.length > 0 &&
                pod.item.map((item, idx) => <li key={idx}>{item}</li>)}
            </ul>
          </div>
        ))}
      </div>
      <div className={classes.snackDetailsWrapper}>
        <div className={classes.sideHeading}>A universe of snacks, just a click away!</div>
        <div className={classes.snackDetail}>
          <p className={classes.snackP}>
            <b>
            AlpaaGo: Hygiene, Taste, Ease, and Affordability!
            </b>
          </p>
          <p className={classes.snackP}>
          We prioritize taste, hygiene, 
          and affordability to ensure every snack meets the highest standards. 
          Our goal is to deliver delicious, safe, and budget-friendly options for all.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Product;
